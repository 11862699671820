/*@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");*/

.container{
    border-radius: 0px 0px 200px 0px;
    border-bottom: 1px solid rgba(18, 101, 61, 0.30) !important;
    background: radial-gradient(50% 50.06% at 50% 100.01%, rgba(11, 225, 122, 0.20) 0%, rgba(11, 225, 122, 0.00) 100%), #EBF6F1 !important;
}

.text{
    /*font-family: 'Noto Sans Hebrew', sans-serif;*/
    color: var(--neutral-payro-black, #252F35) !important;
    text-align: center;
    font-style: normal;
    font-weight: 600 ;
    line-height: 64px !important; /* 100% */
}


.button {
    width: 100% !important;
    height: 60px !important;
    padding: 9px 18px ; /* Vertical and Horizontal padding */
    margin-bottom: 24px; /* Margin bottom */
    border-radius: 16px !important; /* Border radius */
    box-shadow: 0px 4px 16px 0px rgba(255, 255, 255, 0.35) inset, 0px 20px 20px 0px rgba(11, 225, 122, 0.10) !important; /* Box shadow */
    color: #12653D !important;
    text-align: right;
    /*font-family: 'Noto Sans Hebrew', sans-serif ;*/
    font-size: 24px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal !important;
}
